import { Timer as TimerIcon } from '@mui/icons-material';
import { Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { VisitFieldsFragment } from 'api';
import { Card, TitleWithSubtitle } from 'components';
import { useVisitPresenter } from 'hooks/useVisitPresenter';
import { formatAddress } from 'system';
import TenantInfo from './TenantInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deadline: {
      fontSize: '18px',
      fontWeight: 500,
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    detailText: {
      color: theme.palette.text.secondary,
      fontSize: '13px',
      marginTop: theme.spacing(0.5),
    },
    item: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      marginTop: theme.spacing(1),
      '&:first-child': {
        marginTop: 0,
      },
    },
  })
);

export default function DeadlineCard({ visit }: { visit: VisitFieldsFragment }) {
  const styles = useStyles();
  const { dueDate, tenant, occupancy, unitName } = useVisitPresenter({
    visit,
  });
  return (
    <Card>
      <Grid container direction="column">
        <Grid className={styles.item} item>
          <TimerIcon className={styles.icon} fontSize="small" color="error" />
          <Typography className={styles.deadline}>
            {visit.delay ? 'Deadline Past Due' : 'Deadline Scheduled'}
          </Typography>
        </Grid>
        <TitleWithSubtitle title={dueDate} subtitle="Due Date" />
        <TitleWithSubtitle
          title={
            (tenant?.name ?? occupancy === 'common')
              ? (unitName ?? 'Common Area')
              : visit.request?.occupant
                ? 'Occupied'
                : 'Empty Unit'
          }
          subtitle={formatAddress(
            visit.request?.unit.property?.address ?? {},
            visit.request?.unit.name
          )}
        />
        {tenant ? (
          <>
            <TenantInfo tenant={tenant} />
            {visit.request?.additionalTenants?.map((t, i) => <TenantInfo key={i} tenant={t} />)}
          </>
        ) : (
          <></>
        )}
      </Grid>
    </Card>
  );
}
